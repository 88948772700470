import { Drawer, IconButton, AppBar as MuiAppBar } from '@mui/material';
import { MobileUserInfoBox } from 'src/shared/components/UserInfoBox';
import { useBooleanState, useMedia } from 'src/shared/hooks';
import { Close } from 'src/shared/icons/Close';
import { Menu as MenuIcon } from 'src/shared/icons/Menu';
import { makeStyles } from 'tss-react/mui';
import { Logo } from './Logo';
import { Menu } from './Menu';
import { AppBarHeight, drawerWidth } from './constants';

export const MobileDrawer = () => {
  const { classes } = useStyles();
  const [open, toggle] = useBooleanState();
  const isMd = useMedia('md');

  return (
    <MuiAppBar classes={{ root: classes.appbar }}>
      <IconButton onClick={() => toggle()}>{open ? <Close /> : <MenuIcon />}</IconButton>
      <Logo />
      <div />
      <Drawer
        open={open}
        anchor="left"
        variant="temporary"
        ModalProps={{ keepMounted: true }} // Better open performance on mobile.
        classes={{ root: classes.drawer }}
        onClose={(_, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            toggle();
          }
        }}
      >
        {!isMd && <MobileUserInfoBox onNavigate={toggle} />}
        <Menu onClose={toggle} />
      </Drawer>
    </MuiAppBar>
  );
};
const useStyles = makeStyles()((theme) => ({
  appbar: {
    background: theme.palette.neutral.dark,
    color: theme.palette.common.white,
    height: AppBarHeight,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .MuiIconButton-root': {
      color: theme.palette.common.white,
    },
    paddingLeft: 16,
    paddingRight: 16,
  },
  drawer: {
    top: AppBarHeight,
    '& .MuiBackdrop-root': {
      top: AppBarHeight,
    },
    '& .MuiDrawer-paper': {
      paddingLeft: 24,
      paddingRight: 24,
      height: 'calc(100% - 120px)',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        paddingLeft: 16,
        paddingRight: 16,
      },
      top: AppBarHeight,
      background: theme.palette.neutral.dark,
      color: theme.palette.common.white,
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      borderTop: `1px solid ${theme.palette.neutral.lightenDark}`,
    },
  },
}));
