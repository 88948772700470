import { t } from '@lingui/macro';
import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import clsx from 'clsx';
import * as React from 'react';
import { useMutation } from 'react-query';
import { useMatches, useNavigate } from 'react-router';
import { sharedAuthPostKeygen } from 'src/core/query/sharedKeygen';
import { MenuItem, menuItems } from 'src/core/router';
import { getAppDirection } from 'src/core/theme/getAppDirection';
import { makeStyles } from 'tss-react/mui';
import { ArrowNext } from '../components/BidiIcons/ArrowNext';
import { useRemoveToken } from '../hooks/useRemoveToken';
import { useConfirm } from '../contexts/confirm/confirm.context';
interface OwnProps {
  onClose?: () => void;
}
export const Menu = (props: OwnProps) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const matches = useMatches();
  const confirm = useConfirm();
  const logout = useRemoveToken();
  const { mutateAsync } = useMutation(sharedAuthPostKeygen('/api/v1/auth/user/logout'));

  const isRouteActive = (pathname: string) =>
    !!pathname && matches.some((match) => match.pathname.includes(pathname));

  const [expandedItem, setExpandedItem] = React.useState<number | null>(null);

  const handleListItemClick = (item: MenuItem, index: number | null) => () => {
    if (item.to) {
      navigate(item.to);
      props.onClose?.();
    }
    if (item.title === t`Logout`) {
      confirm()
        .then(async () => {
          await mutateAsync({ params: {} });
          logout();
        })
        .catch(() => console.log('no'));
    }
    if (item.children?.length) {
      setExpandedItem((prev) => (prev === index ? null : index));
    }
  };

  return (
    <List>
      {menuItems.map((item, itemIndex) => (
        <React.Fragment key={itemIndex}>
          <ListItem
            className={clsx(classes.listItem, {
              [classes.parent]: !!item.children?.length,
              [classes.expanded]: expandedItem === itemIndex,
            })}
          >
            <ListItemButton
              onClick={handleListItemClick(item, itemIndex)}
              className={clsx(classes.listItemButton, {
                [classes.activeRoute]: isRouteActive(item.to || ''),
              })}
            >
              <ListItemIcon className={classes.listItemIcon}>{item.icon}</ListItemIcon>
              <ListItemText>{item.title}</ListItemText>
              {!!item.children?.length && (
                <ArrowNext
                  className={clsx(classes.expandIcon, {
                    [classes.rotate]: expandedItem === itemIndex,
                  })}
                />
              )}
            </ListItemButton>
            <Collapse in={expandedItem === itemIndex} timeout="auto">
              {item.children &&
                item.children.map((item, itemIndex) => (
                  <ListItem component="div" key={itemIndex} className={classes.listItem}>
                    <ListItemButton
                      onClick={handleListItemClick(item, itemIndex)}
                      className={clsx(classes.listItemButton, classes.childListItemButton, {
                        [classes.activeRoute]: isRouteActive(item.to || ''),
                      })}
                    >
                      <ListItemText>{item.title}</ListItemText>
                    </ListItemButton>
                  </ListItem>
                ))}
            </Collapse>
          </ListItem>

          {item.divider && <Divider className={classes.divider} />}
        </React.Fragment>
      ))}
    </List>
  );
};
const useStyles = makeStyles<
  void,
  'listItemIcon' | 'activeRoute' | 'listItemButton' | 'expanded'
>()((theme, _, classes) => ({
  parent: {
    [`&:has(.${classes.activeRoute}):not(.${classes.expanded}) > .${classes.listItemButton}`]: {
      background: theme.palette.neutral.lightenDark,
      [`& .${classes.listItemIcon}`]: {
        color: theme.palette.primary.main,
      },
    },
  },
  expanded: {},
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  listItemButton: {
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      background: theme.palette.neutral.lightenDark,
    },
  },
  activeRoute: {
    background: theme.palette.neutral.lightenDark,
    [`& .${classes.listItemIcon}`]: {
      color: theme.palette.primary.main,
    },
  },
  rotate: {
    transform: `${getAppDirection() === 'rtl' ? 'rotate(-90deg)' : 'rotate(90deg)'}`,
  },
  listItemIcon: {
    minWidth: 36,
    color: theme.palette.common.white,
  },
  childListItemButton: {
    paddingLeft: theme.spacing(6),
  },
  expandIcon: {
    color: theme.palette.common.white,
    minWidth: 'auto',
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.short,
    }),
  },
  divider: {
    borderColor: theme.palette.neutral.lightenDark,
    borderStyle: 'dashed',
    borderWidth: 1.5,
  },
}));
