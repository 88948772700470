import { t } from '@lingui/macro';
import { Avatar, Button, Chip, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { ArrowPrev } from '../BidiIcons/ArrowPrev';
import { ActionsSection } from './ActionsSection';
import { useAuth } from 'src/shared/contexts/auth/auth.context';

export const DesktopUserInfoBox = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { user } = useAuth();
  const accountStatus = user?.status?.account;

  const handleRedirectToAuthentication = () => {
    navigate('/authentication');
  };

  const color =
    accountStatus === 'active' ? 'success' : accountStatus === 'rejected' ? 'warning' : 'secondary';

  return (
    <div className={classes.root}>
      <Button
        variant="contained"
        color={color}
        className={classes.action}
        startIcon={<ArrowPrev />}
        onClick={handleRedirectToAuthentication}
      >
        {accountStatus === 'active'
          ? t`Authenticated`
          : accountStatus === 'pending'
            ? t`Authentication pending`
            : accountStatus === 'rejected'
              ? t`Authentication rejected`
              : t`Authenticate now`}
      </Button>
      <div className={classes.info}>
        <Avatar sx={{ width: 40 }} src={''} />
        <Typography variant="body1" color="black">
          {user?.first_name}
        </Typography>
        <Chip
          size="small"
          label={user?.role === 'Investor' ? t`Investor` : t`Broker`}
          classes={{ root: classes.chip }}
          variant="outlined"
        />
        <ActionsSection role={user!.role} username={user?.first_name} />
      </div>
    </div>
  );
};
const useStyles = makeStyles()((theme) => ({
  root: {
    height: 48,
    background: theme.palette.neutral.white,
    borderRadius: theme.shape.borderRadius,
    padding: 4,
    display: 'flex',
    position: 'relative',
    boxShadow: '0px 8px 24px 0px rgba(11, 18, 38, 0.04)',
  },
  action: {
    height: '100%',
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.up('md')]: {
      paddingRight: 280,
    },
  },
  info: {
    height: 48,
    zIndex: 2,
    position: 'absolute',
    top: 0,
    right: 0,
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.common.white,
    display: 'flex',
    paddingLeft: 4,
    paddingRight: 4,
    alignItems: 'center',
    gap: 8,
  },
  chip: {
    background: theme.palette.neutral.lightBlue,
    color: theme.palette.secondary.dark,
  },
}));
