import { t } from '@lingui/macro';
import { Toolbar, ToolbarProps, Typography } from '@mui/material';
import { getAppLocale } from 'src/locales/utils';
import { makeStyles } from 'tss-react/mui';
import { LandingUrl } from 'src/core/domain/LandingUrls';

export const Logo = (props: ToolbarProps) => {
  const { classes } = useStyles();
  return (
    <a href={LandingUrl}>
      <Toolbar className={classes.root} {...props}>
        <img
          src={getAppLocale() === 'en' ? '/tokenisionLogo.png' : '/metrilandLogo.png'}
          alt="Logo"
          className={classes.logo}
        />
        <Typography sx={{ marginLeft: 2 }} variant="h2" color="inherit">
          {t`Metri Land`}
        </Typography>
      </Toolbar>
    </a>
  );
};

const useStyles = makeStyles()(() => ({
  root: {
    cursor: 'pointer',
  },
  logo: {
    width: 32,
    height: 32,
  },
}));
