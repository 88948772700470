import { createContext, PropsWithChildren, useCallback, useContext, useState } from 'react';
import { USER_AUTH_TOKEN, USER_ROLE } from '../../domain/auth/localStorageKeys';
import { LandingUrl } from 'src/core/domain/LandingUrls';
import { useNavigate } from 'react-router-dom';
import { GET } from 'src/core/query/en/authClient';
import { InitialValue, UserInfo } from './type';
import useFirstRenderEffect from '../../hooks/use-first-render-effect';

const initialValue = {
  user: null,
  isPending: true,
  fetchUserInfo: () => {},
  logout: () => {},
};

const authContext = createContext<InitialValue>(initialValue);

export default function AuthContextProvider({ children }: PropsWithChildren) {
  const [isPending, setIsPending] = useState(true);
  const [user, setUser] = useState<UserInfo | null>(null);
  const navigate = useNavigate();
  const token = localStorage.getItem(USER_AUTH_TOKEN);

  const fetchUserInfo = useCallback(() => {
    setIsPending(true);
    Promise.all([
      GET('/api/v1/auth/user/investor/info/personal'),
      GET('/api/v1/auth/user/investor/auth/status'),
    ])
      .then(([userRes, userStatusRes]) => {
        const user = {
          ...(userRes.data!.data as UserInfo),
          status: userStatusRes.data!.data,
          // TODO remove hardcoded role and read from api response or jwt token
          role: 'Investor' as const,
        };
        if (user.status?.account !== 'active' && user.status?.account !== 'pending') {
          navigate('/authentication');
        }
        setTimeout(() => {
          user && setUser(user);
          setIsPending(false);
        }, 50);
      })
      .catch(() => {
        navigate('/auth/login');
        setIsPending(false);
      });
  }, [navigate]);

  useFirstRenderEffect(() => {
    if (!token) {
      navigate('/auth/login');
    } else {
      fetchUserInfo();
    }
  });

  const logout = useCallback(() => {
    localStorage.removeItem(USER_AUTH_TOKEN);
    localStorage.removeItem(USER_ROLE);
    window.location.href = LandingUrl;
  }, []);

  return (
    <authContext.Provider
      value={{
        user,
        fetchUserInfo,
        isPending,
        logout,
      }}
    >
      {children}
    </authContext.Provider>
  );
}

export const useAuth = () => useContext(authContext);
