import { PropsWithChildren } from 'react';
import { useAuth } from '../contexts/auth/auth.context';
import { makeStyles } from 'tss-react/mui';
import { NotCompletedAuthCard } from './NotCompletedAuthCard';

export function AuthGuard({ children }: PropsWithChildren) {
  const { user } = useAuth();
  const { classes } = useStyles();

  return user && user.status?.account === 'active' ? (
    children
  ) : (
    <div className={classes.container}>
      <NotCompletedAuthCard />
    </div>
  );
}

const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
    paddingTop: '20dvh',
    display: 'flex',
    justifyContent: 'center',
  },
}));
