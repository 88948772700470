import { useNavigate } from 'react-router-dom';
import { USER_AUTH_TOKEN, USER_ROLE } from 'src/shared/domain/auth/localStorageKeys';

export const useRemoveToken = () => {
  const router = useNavigate();

  return () => {
    localStorage.removeItem(USER_AUTH_TOKEN);
    localStorage.removeItem(USER_ROLE);
    router('/auth/login');
  };
};
