import createClient from 'openapi-fetch';
import { getAppBaseUrl } from 'src/locales/utils';
import { USER_AUTH_TOKEN, USER_ROLE } from '../domain/auth/localStorageKeys';
import { toast } from 'react-toastify';

type ClientConfigType = Parameters<typeof createClient>[0];

export const globalUserToken: { value: string | undefined; webSocketCode: string | undefined } = {
  value: undefined,
  webSocketCode: undefined,
};

export const defaultClientConfig: ClientConfigType = {
  baseUrl: getAppBaseUrl(),
  headers: {
    get Authorization() {
      const token = localStorage.getItem(USER_AUTH_TOKEN);
      return token ? `Bearer ${token}` : '';
    },
  },
  fetch: async (url: RequestInfo | URL, config?: RequestInit) => {
    const response = await fetch(url, config);
    if (!response.ok) {
      // no access-token OR expired access-token
      if (response.status === 401) {
        const responseBody = await response.json();
        // TODO update if block after api changes
        if ((responseBody.data.code as string) === 'activeAccountRequired') {
          toast.error('برای دسترسی به این منبع، احراز هویت شما باید تکمیل و تایید شود.');
        } else {
          toast.error('لطفا مجددا وارد پنل شوید.');
          localStorage.removeItem(USER_AUTH_TOKEN);
          localStorage.removeItem(USER_ROLE);
          window.location.href = '/auth/login';
        }
      } else if (response.status === 429) {
        toast.error('تعداد درخواست‌ها بیش از حد مجاز است. لطفاً منتظر بمانید و مجدداً تلاش کنید.');
      } else if (response.status === 404) {
        // should be handled properly in different pages and different scenarios
      } else if (response.status === 400) {
        // should be handled properly in different forms
      } else if (response.status === 403) {
        toast.error('شما دسترسی مجاز برای انجام این کار ندارید.');
      } else if (response.status >= 500) {
        if (response.status === 502) {
          toast.error('سرور موقتا در دسترس نیست. لطفا دقایقی دیگر مجددا تلا کنید.');
        } else if (response.status === 503) {
          toast.error(
            'مشکلی از سمت ارائه دهنده سرویس به وجود آمده است، لطفا دقایقی دیگه مجددا تلاش کنید.',
          );
        } else {
          toast.error('خطای سیستمی. لطفاً بعداً تلاش کنید.');
        }
      } else {
        console.log('Unhandled error status: ', response);
        toast.error('خطای سیستمی. لطفاً بعداً تلاش کنید.');
      }
      return response;
    } else {
      return response;
    }
  },
};
